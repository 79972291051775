
import { useUiStore } from '@/stores/ui'

export default {
  name: 'BannerBonusBank',
  setup() {
    const uiStore = useUiStore()
    return {
      uiStore,
    }
  },
  methods: {
    closePopup() {
      this.uiStore.showBannerBonusBank = false
    },
  },
}
